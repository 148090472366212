import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = [ "map" ]

    connect() {
        const lat = parseFloat(this.data.get("lat"))
        const lng = parseFloat(this.data.get("lng"))

        if (isNaN(lat) || isNaN(lng)) {
            console.error("Invalid lat/lng provided")
            return
        }

        const mapOptions = {
            center: { lat, lng },
            zoom: 14,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
        }

        this.map = new google.maps.Map(this.mapTarget, mapOptions)

        new google.maps.Marker({
            position: { lat, lng },
            map: this.map
        })
    }
}