import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "componentsDropdown", 'loading', 'componentsInputs', 'error' ]

    connect() {
        this.componentsByTypePath = this.data.get('componentsByTypePath');
        this.cache = {};
    }

    fetchComponents(event) {
        const componentType = event.target.value;
        if (componentType === "") {
            this.clearComponentsDropdown;
            return;
        }

        // Check if data is cached
        if (this.cache[componentType]) {
            this.updateComponentsDropdown(this.cache[componentType])
            return
        }

        // Show loading spinner and hide error message
        if (this.hasLoading) {
            this.loadingTarget.style.display = "inline-block"
        }
        if (this.hasError) {
            this.errorTarget.style.display = "none"
        }

        const url = `${this.componentsByTypePath}?component_type=${encodeURIComponent(componentType)}`;

        fetch(url, {
            headers: {
                "Accept": "application/json"
            }
        })

        .then(response => {
            if (!response.ok) {
                throw new Error(`Network response was not ok (${response.status})`);
            }

            return response.json();
        })
        .then(data => {
            this.cache[componentType] = data;
            this.updateComponentsDropdown(data);
            // Hide loading spinner
            if (this.hasLoading) {
                this.loadingTarget.style.display = "none"
            }
            this.componentsInputsTarget.classList.remove('d-none');
        })
        .catch(error => {
            console.error("Error fetching components:", error)
            // Hide loading spinner and show error message
            if (this.hasLoading) {
                this.loadingTarget.style.display = "none"
            }
            if (this.hasError) {
                this.errorTarget.style.display = "block"
            }
        });
    }

    updateComponentsDropdown(components) {
        const dropdown = this.componentsDropdownTarget;
        dropdown.innerHTML = '<option value="">Seleccionar componente...</option>';

        components.forEach(component => {
            const option = document.createElement("option");
            option.value = component.id;
            option.text = component.model;
            dropdown.appendChild(option);
        })
    }

    clearComponentsDropdown() {
        const dropdown = this.componentsDropdownTarget;
        dropdown.innerHTML = '<option value="">Selecctionar componente...</option>';
    }
}