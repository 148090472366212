const uppyExecution = () => {
    const inputs = document.querySelectorAll('.optimize-images');
    const submitButton = document.getElementById('submitButton');
    let activeUploads = 0;

    inputs.forEach(input => {
        input.addEventListener('change', async (e) => {
            const files = [...e.target.files];
            const currentInput = input; // Capture the input element
            const dataLimit = currentInput.dataset.maxsize;
            let progressContainer = document.getElementById(`upload-progress-container-${currentInput.dataset.typeDocument}`);

            // Increment the active uploads counter
            activeUploads += 1;

            // Disable the submit button and the current input
            submitButton.disabled = true;
            currentInput.disabled = true;

            try {
                for (const file of files) {
                    // Create a unique identifier for the file (optional)
                    if (file.size > dataLimit) {
                        const parsedSize = dataLimit / 1024 / 1024;
                        const errorContainer = document.createElement('div');
                        errorContainer.classList.add('alert-danger', 'p-3', 'mt-3');
                        errorContainer.style.borderRadius = '0.5rem';
                        errorContainer.textContent = `El archivo ${file.name} excede el tamaño permitido de ${parsedSize}MB`;

                        currentInput.insertAdjacentElement('afterend', errorContainer);

                        setTimeout(() => {
                            if (errorContainer) {
                                errorContainer.classList.add('fade-out');
                                errorContainer.addEventListener('transitionend', () => {
                                    errorContainer.remove();
                                }, { once: true });
                            }
                        }, 5000);

                        activeUploads -= 1;
                        if (activeUploads === 0) {
                            submitButton.disabled = false;
                        }
                    } else {
                        const fileId = `${currentInput.dataset.visitId}-${currentInput.dataset.typeDocument}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

                        // Create and display a progress bar for this file
                        const progressBar = createProgressBar(file.name, fileId, progressContainer);

                        uploadFile(file, currentInput, progressBar, fileId)
                            .then(response => {
                                console.log(`File ${file.name} uploaded successfully`, response);
                            })
                            .catch(error => {
                                console.error(`Error uploading file ${file.name}`, error);
                                // Optionally, display an error message next to the progress bar
                                displayError(progressBar, `Failed to upload: ${error.message}`);
                            })
                            .finally(() => {
                                // Decrement the active uploads counter
                                activeUploads -= 1;

                                // If no active uploads remain, re-enable the submit button and input
                                if (activeUploads === 0) {
                                    submitButton.disabled = false;
                                }

                                setTimeout(() => {
                                    const progressContainerElement = document.getElementById(`file-upload-${fileId}`);
                                    if (progressContainerElement) {
                                        progressContainerElement.remove();
                                    }
                                }, 3000);
                            });
                    }
                }
            } catch (error) {
                console.log('Unexpected error:', error);

                alert('An unexpected error occurred during the upload.');
            } finally {
                // Re-enable the current input and clear the attach docs
                currentInput.value = '';
                currentInput.disabled = false;
            }
        });
    });


    /**
     * Uploads a single file using XMLHttpRequest and tracks its progress.
     * @param {File} file - The file to upload.
     * @param {HTMLElement} input - The input element triggering the upload.
     * @param {Function} onProgress - Callback for progress updates.
     * @returns {Promise} - Resolves when the upload is complete.
     */

    const createProgressBar = (fileName, fileId, progressContainer) => {
        // Create a container for the file and its progress bar
        const fileContainer = document.createElement('div');
        fileContainer.className = 'file-upload-container';
        fileContainer.id = `file-upload-${fileId}`;
        fileContainer.style.marginBottom = '10px';

        // Create a label for the file name
        const fileLabel = document.createElement('span');
        fileLabel.textContent = `Uploading ${fileName}: `;
        fileLabel.style.display = 'block';
        fileLabel.style.marginBottom = '5px';

        // Create the progress bar
        const progressBar = document.createElement('progress');
        progressBar.max = 100;
        progressBar.value = 0;
        progressBar.style.width = '100%';
        progressBar.style.height = '20px';
        progressBar.style.display = 'block';

        // Append the label and progress bar to the container
        fileContainer.appendChild(fileLabel);
        fileContainer.appendChild(progressBar);

        // Append the container to the main progress container
        progressContainer.appendChild(fileContainer);

        return progressBar;
    }

    const displayError = (progressBar, message) => {
        const fileContainer = progressBar.parentNode;
        const errorMsg = document.createElement('span');
        errorMsg.textContent = message;
        errorMsg.style.color = 'red';
        errorMsg.style.display = 'block';
        errorMsg.style.marginTop = '5px';
        fileContainer.appendChild(errorMsg);
    }

    const uploadFile = (file, input, progressBar, fileId) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type_document', input.dataset.typeDocument);
            formData.append('visit_id', input.dataset.visitId);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/uploads', true);

            // Set the CSRF token header
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);

            //Handle successful upload
            xhr.addEventListener('load', (event) => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    try {
                        if (event.lengthComputable) {
                            const percentComplete = (event.loaded / event.total) * 100;
                            progressBar.value = percentComplete;
                        }
                        const response = JSON.parse(xhr.responseText);
                        console.log(response);
                        resolve(response);
                    } catch (err) {
                        reject(new Error('Invalid JSON response from server'));
                    }
                } else {
                    reject(new Error(`Upload failed with status ${xhr.status}`));
                }
            });

            //Handle errors
            xhr.addEventListener('error', () => {
                reject(new Error('Network error occurred during the upload.'));
            });

            xhr.addEventListener('abort', () => {
                reject(new Error('Upload was aborted'));
            });

            //Send the request
            xhr.send(formData);
        });
    };
};

export default uppyExecution;