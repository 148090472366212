let autocomplete;
let input;

let autocomplete2;
let input2;

let autocompleteClient;
let inputClient;

const adminAddressAutocomplete = () => {
  const options = {
    componentRestrictions: { country: 'es' },
    fields: ["address_components", "geometry"],
    types: ["geocode"],
  }

  if (!!document.querySelector('.autocompleteAddress1')) {
    input = document.querySelector('.autocompleteAddress1');
    autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener('place_changed', () => {
      fillInAddress(autocomplete.getPlace(), input.parentElement.parentElement);
      $(input).trigger('change');
    });
  }

  if (!!document.querySelector('.autocompleteAddress1_2')) {
    input2 = document.querySelector('.autocompleteAddress1_2');
    autocomplete2 = new google.maps.places.Autocomplete(input2, options);
    autocomplete2.addListener('place_changed', () => {
      fillInAddress(autocomplete2.getPlace(), input2.parentElement.parentElement);
      $(input2).trigger('change');
    });
  }

  if (!!document.querySelector('.autocompleteAddress1Client')) {
    inputClient = document.querySelector('.autocompleteAddress1Client');
    autocompleteClient = new google.maps.places.Autocomplete(inputClient, options);

    autocompleteClient.addListener('place_changed', () => {
      fillInAddress(autocompleteClient.getPlace(), inputClient.parentElement.parentElement);
      $(inputClient).trigger('change');
    });
  }
}

const fillInAddress = (place, container) => {
  let address1Field = container.querySelector('#autocompleteAddress1');
  let address2Field = container.querySelector('#autocompleteAddress2');
  let postcodeField = container.querySelector('#autocompletePostcode');
  let areaField = container.querySelector('#autocompleteArea');
  let comunidadField = container.querySelector('#autocompleteComunidad');
  let cityField = container.querySelector('#autocompleteCity');
  let countryField = container.querySelector('#autocompleteCountry');
  let address1 = '';

  place.address_components.forEach(component => {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number': {
        address1 += component.long_name;
        break;
      }

      case 'route': {
        address1 = `${component.short_name} ${address1}`;
        break;
      }

      case 'postal_code': {
        postcodeField.value = component.long_name
        break;
      }

      case 'administrative_area_level_1': {
        if (!!comunidadField) {
          comunidadField.value = component.long_name;
          $(comunidadField).trigger('change');
        }
        break;
      }

      case 'administrative_area_level_2': {
        if (!!areaField) {
          areaField.value = component.long_name;
          $(areaField).trigger('change');
        }
        break;
      }

      case 'locality': {
        cityField.value = component.long_name
        break;
      }

      case 'country': {
        countryField.value = component.long_name
        break;
      }
    }
  });
  address1Field.value = address1;
  if (address2Field != null) {
    address2Field.focus();
  }
  $(container).find('.showAfterAutocomplete').each(function(i, el) {
    $(el).removeClass('collapse').removeClass('hidden');
  });
  $(container).find('.mapLatitude').each(function(i, el) {
    $(el).val(place.geometry.location.lat()).trigger('change');
  });
  $(container).find('.mapLongitude').each(function(i, el) {
    $(el).val(place.geometry.location.lng()).trigger('change');
  });
}

export {adminAddressAutocomplete, fillInAddress};
