import ApplicationController from "./application_controller"
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
    connect () {
        console.log("ReplyFormController connected")
        document.addEventListener("replyCreated", this.handleReplyCreated)
    }

    disconnect() {
        console.log("ReplyFormController disconnected")
        document.removeEventListener("replyCreated", this.handleReplyCreated)
      }

    handleReplyCreated = (event) => {
        // Your JavaScript function code here
        console.log("Custom event received:", event.detail)
        // For example, reset the form
        this.element.reset()
      }
}