const toggleFormByRadio = () => {
  $('[data-show-form-selector]').each(function(i, el) {
    showElements(el);

    $(el).on('change', function(radio) {
      showElements(el);
    });
  });

  $('[data-hide-form-selector]').each(function(i, el) {
    hideElements(el);

    $(el).on('change', function(radio) {
      hideElements(el);
    });
  });
};

function showElements(el) {
  if($(el).prop("checked")) {
    $($(el).attr('data-show-form-selector')).each(function(ii, shownEl) {
      if($(shownEl).prop('tagName') == 'A') {
        $(shownEl).css('display', 'inline-block');
      } else {
      $(shownEl).css('display', 'block');
      const input = $(shownEl).find('input');
      if (!!input) {
        input.attr('disabled', false);
      }
      }
    });
  }
}

function hideElements(el) {
  if($(el).prop("checked")) {
    $($(el).attr('data-hide-form-selector')).each(function(ii, hiddenEl) {
      $(hiddenEl).css('display', 'none');
      $(hiddenEl).find('input').val('');
      $(hiddenEl).find('textarea').val('');
      const input = $(hiddenEl).find('input');
      if (!!input) {
        input.attr('disabled', true);
      }
    });
  }
}

export default toggleFormByRadio;
