import { showMap, showMapByCoordinates } from '../custom/map';

export default function selectRoofBackOffice() {
  $(document).on('change', '.mapLatitude, .mapLongitude', function(){
    var lat = parseFloat($(this).closest('form').find('.mapLatitude').val());
    var lng = parseFloat($(this).closest('form').find('.mapLongitude').val());

    if (!!document.getElementById('map') && lat && lng) {
      showMapByCoordinates(document.getElementById('map'), lat, lng);
    }
  });

  if (!document.getElementById('map') || !document.getElementById('addressForMap') || !document.getElementById('addressForMap').dataset['address']) {
    return;
  }

  const address = document.getElementById('addressForMap').dataset.address;
  showMap(address, 19, 'satellite', true);
}
