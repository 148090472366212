import ApplicationController from './application_controller'
import toastr from 'toastr';

export default class extends ApplicationController {
  static targets = ['clockInButton', 'clockOutButton', 'spinner']

  connect() {
    console.log("ClockController connected")
    StimulusReflex.register(this)
  }

  clockIn(e) {
    console.log("Clocking in...")
    e.preventDefault();

   this.handleClockAction({
    buttonTarget: this.clockInButtonTarget,
    spinnerColorClass: 'text-primary',
    reflexMethod: 'ClockReflex#clock_in'
   })
  }

  clockOut(e) {
    console.log("Clocking out...")
    e.preventDefault();

    this.handleClockAction({
      buttonTarget: this.clockOutButtonTarget,
      spinnerColorClass: 'text-danger',
      reflexMethod: 'ClockReflex#clock_out'
     })
  }

  handleClockAction({ buttonTarget, spinnerColorClass, reflexMethod }) {
    // Hide the button
    buttonTarget.classList.add('d-none')

    // Clear any old color classes and show spinner with new color
    this.spinnerTarget.classList.remove('text-primary', 'text-danger', 'd-none')
    this.spinnerTarget.classList.add(spinnerColorClass)

    // Wait 2 seconds before fetching location
    setTimeout(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords
            this.stimulate(reflexMethod, latitude, longitude)
          },
          (error) => {
            console.error("Error fetching location:", error)
            // Fallback: call reflex method without coordinates
            this.stimulate(reflexMethod)
          }
        )
      } else {
        alert("Geolocation is not supported by this browser.")
        // Call reflex method without coordinates
        this.stimulate(reflexMethod)
      }
    }, 2000)
  }

  reflexSuccess(event) {
    toastr.success('Registro exitoso!')
  }

  reflexError(event) {
    this.spinnerTarget.classList.add('d-none')
    if (this.spinnerTarget.classList.contains('text-primary')) {
      this.clockInButtonTarget.classList.remove('d-none')
    } else {
      this.clockOutButtonTarget.classList.remove('d-none')
    }
    toastr.error('Ups! Algo salió mal')
  }
}